import {
  HoverHelp,
  makeFormikNumericTextFieldProps,
  makeFormikTextFieldProps
} from "@lightningkite/mui-lightning-components"
import {ArrowDropDown} from "@mui/icons-material"
import {MenuItem, TextField} from "@mui/material"
import {BusinessForm as BusinessFormType} from "api/sdk"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import {makeFormikRadioInputProps, RadioInput} from "components/RadioInput"
import {useFormik} from "formik"
import React, {FC} from "react"
import {stateCodeOptions} from "utils/constants"
import {businessFormLabels} from "utils/helpers/enumLabels"
import {emptyBusinessInfo} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"

const validationSchema = yup.object().shape({
  businessForm: yup.string().required("Required"),
  stateOfFormation: yup.string().required("Required"),
  ein: yup.string().required("Required"),
  fiscalYearEndMonth: yup.number().min(0).max(12).required("Required"),
  fiscalYearEndDay: yup.number().min(0).max(31).required("Required"),
  naicsCode: yup.string().required("Required")
})

const BusinessForm: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props

  const formik = useFormik({
    initialValues: {
      businessForm: application.business?.businessForm ?? null,
      stateOfFormation: application.business?.stateOfFormation ?? "",
      ein: application.business?.entityIdentificationNumber ?? "",
      fiscalYearEndMonth: application.business?.fiscalYearEnd?.month ?? 12,
      fiscalYearEndDay: application.business?.fiscalYearEnd?.day ?? 31,
      naicsCode: application.business?.naicsCodeOrDescription ?? ""
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      return saveProgress({
        business: {
          ...(application.business ?? emptyBusinessInfo),
          businessForm: values.businessForm,
          stateOfFormation: values.stateOfFormation,
          entityIdentificationNumber: values.ein,
          fiscalYearEnd: {
            month: values.fiscalYearEndMonth,
            day: values.fiscalYearEndDay
          },
          naicsCodeOrDescription: values.naicsCode
        }
      })
        .then(() => {
          resetForm({values})
          nextStep()
        })
        .catch(() => alert("Error saving"))
    }
  })

  return (
    <>
      <FormSection
        disableTopPadding
        title="Formation"
        subtitle="If you do not have a formal LLC, Corporation, etc, you will select “Sole Proprietorship” to indicate you are a sole proprietor. If you have an LLC taxed as a Corporation, you will select “LLC.”"
      >
        <RadioInput
          label="Business Form"
          options={Object.keys(BusinessFormType).map((key) => ({
            value: key,
            label: businessFormLabels[key as BusinessFormType]
          }))}
          {...makeFormikRadioInputProps(formik, "businessForm")}
        />

        <TextField
          fullWidth
          select
          label="State of formation"
          {...makeFormikTextFieldProps(formik, "stateOfFormation")}
        >
          {stateCodeOptions.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </TextField>

        <HoverHelp description="This is the federal taxpayer ID for your business. If you have a sole proprietorship, perhaps you do not have an EIN and the taxpayer ID for your business is your social security number. If this is the case, enter your SSN here. Sometimes we need to obtain an EIN for your business if you do not already have one. If that is the case, we will discuss this with you and can often obtain this as a courtesy to you free of charge.">
          <TextField
            fullWidth
            label="Business EIN or Social Security Number"
            {...makeFormikTextFieldProps(formik, "ein")}
          />
        </HoverHelp>
      </FormSection>

      <FormSection
        title="Fiscal Year"
        subtitle="Enter your business fiscal year end. This is usually the same as the calendar year end of  12/31, however some businesses have opted for a different fiscal year end."
      >
        <TextField
          fullWidth
          label="Fiscal Year End Month"
          type="number"
          {...makeFormikNumericTextFieldProps(formik, "fiscalYearEndMonth")}
        />
        <TextField
          fullWidth
          label="Fiscal Year End Day"
          type="number"
          {...makeFormikNumericTextFieldProps(formik, "fiscalYearEndDay")}
        />
      </FormSection>

      <FormSection
        title="Business Description"
        subtitle="Enter your business's 6-digit NAICS code if you know it. If you do not, please enter a brief description of what your business does so we may find the NAICS code for."
      >
        <TextField
          fullWidth
          multiline
          label="NAICS Code or Description"
          {...makeFormikTextFieldProps(formik, "naicsCode")}
        />
      </FormSection>

      <AutoLoadingButton
        endIcon={<ArrowDropDown />}
        variant="contained"
        onClick={formik.submitForm}
        sx={{mt: 4}}
      >
        Save and Continue
      </AutoLoadingButton>
    </>
  )
}

export default BusinessForm
