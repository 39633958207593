import {
  AggregateQuery,
  apiCall,
  Condition,
  EntryChange,
  GroupAggregateQuery,
  GroupCountQuery,
  MassModification,
  Modification,
  Query
} from "@lightningkite/lightning-server-simplified"

export interface Address {
  line1: string | null | undefined
  line2: string | null | undefined
  city: string | null | undefined
  state: string | null | undefined
  country: string | null | undefined
  postalCode: string | null | undefined
}
export interface Application {
  _id: string
  owner: string
  trustee: TrusteeInfo | null | undefined
  business: BusinessInfo | null | undefined
  existing: ExistingState | null | undefined
  originalPlan: OriginalPlanInfo | null | undefined
  spouse: SpouseParticipation | null | undefined
  spouseTrusteeInfo: SpouseTrusteeInfo | null | undefined
  desiredTrustName: string | null | undefined
  notes: string
  stripe: StripePurchaseInfo | null | undefined
  signature: ESignatureInfo | null | undefined
  solera: SoleraInfo | null | undefined
  readyForReview: boolean
  review: SignupFormReview | null | undefined
}
export enum BusinessForm {
  LLC = "LLC",
  C = "C",
  S = "S",
  Sole = "Sole",
  Partnership = "Partnership"
}
export interface BusinessInfo {
  name: string | null | undefined
  phone: string | null | undefined
  startedAt: string | null | undefined
  address: Address | null | undefined
  businessForm: BusinessForm | null | undefined
  stateOfFormation: string | null | undefined
  entityIdentificationNumber: string | null | undefined
  naicsCodeOrDescription: string | null | undefined
  fiscalYearEnd: FiscalYearEnd | null | undefined
}
export interface ESignatureInfo {
  id: string
  signed: boolean
}
export interface EmailPinLogin {
  email: string
  pin: string
}
export enum ExistingState {
  None = "None",
  Both = "Both",
  Replace = "Replace"
}
export interface FiscalYearEnd {
  month: number | null | undefined
  day: number | null | undefined
}
export interface HealthStatus {
  level: Level
  checkedAt: string
  additionalMessage: string | null | undefined
}
export enum Level {
  OK = "OK",
  WARNING = "WARNING",
  URGENT = "URGENT",
  ERROR = "ERROR"
}
export interface Memory {
  maxMem: number
  totalMemory: number
  freeMemory: number
  systemAllocated: number
  memUsagePercent: number
}
export interface MetricSpanStats {
  _id: string
  endpoint: string
  type: string
  timeStamp: string
  timeSpan: string
  min: number
  max: number
  sum: number
  count: number
}
export interface OriginalPlanInfo {
  originalPlanDate: string | null | undefined
  originalTrustEin: string | null | undefined
}
export interface ServerHealth {
  serverId: string
  version: string
  memory: Memory
  features: Record<string, HealthStatus>
  loadAverageCpu: number
}
export interface SignupFormReview {
  status: SignupFormStatus
  at: string
  notes: string
}
export enum SignupFormStatus {
  ChangesRequested = "ChangesRequested",
  Rejected = "Rejected",
  Approved = "Approved"
}
export interface SoleraInfo {
  id: string
  status: SoleraStatus
}
export enum SoleraStatus {
  DRAFT = "DRAFT",
  REQUIRES_REVIEW = "REQUIRES_REVIEW",
  READY_FOR_BATCH = "READY_FOR_BATCH",
  IN_PROCESS = "IN_PROCESS",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  ON_HOLD = "ON_HOLD"
}
export enum SpouseParticipation {
  NotApplicable = "NotApplicable",
  Participating = "Participating",
  NotParticipating = "NotParticipating"
}
export interface SpouseTrusteeInfo {
  fullLegalName: string | null | undefined
  email: string | null | undefined
}
export interface StripePurchaseInfo {
  subscriptionId: string
  active: boolean
}
export interface TrusteeInfo {
  givenName: string | null | undefined
  middleName: string | null | undefined
  surname: string | null | undefined
  address: Address | null | undefined
  phone: string | null | undefined
  socialSecurityNumber: string | null | undefined
  birthday: string | null | undefined
}
export interface UploadInformation {
  uploadUrl: string
  futureCallToken: string
}
export interface User {
  _id: string
  email: string
  isSuperUser: boolean
  stripe: string | null | undefined
}

export interface Api {
  readonly auth: {
    refreshToken(userToken: string): Promise<string>
    getSelf(userToken: string): Promise<User>
    emailLoginLink(input: string): Promise<void>
    emailPINLogin(input: EmailPinLogin): Promise<string>
  }
  readonly user: {
    query(input: Query<User>, userToken: string): Promise<Array<User>>
    detail(id: string, userToken: string): Promise<User>
    insertBulk(input: Array<User>, userToken: string): Promise<Array<User>>
    insert(input: User, userToken: string): Promise<User>
    upsert(id: string, input: User, userToken: string): Promise<User>
    bulkReplace(input: Array<User>, userToken: string): Promise<Array<User>>
    replace(id: string, input: User, userToken: string): Promise<User>
    bulkModify(
      input: MassModification<User>,
      userToken: string
    ): Promise<number>
    modifyWithDiff(
      id: string,
      input: Modification<User>,
      userToken: string
    ): Promise<EntryChange<User>>
    modify(
      id: string,
      input: Modification<User>,
      userToken: string
    ): Promise<User>
    bulkDelete(input: Condition<User>, userToken: string): Promise<number>
    delete(id: string, userToken: string): Promise<void>
    count(input: Condition<User>, userToken: string): Promise<number>
    groupCount(
      input: GroupCountQuery<User>,
      userToken: string
    ): Promise<Record<string, number>>
    aggregate(
      input: AggregateQuery<User>,
      userToken: string
    ): Promise<number | null | undefined>
    groupAggregate(
      input: GroupAggregateQuery<User>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>>
  }
  readonly application: {
    query(
      input: Query<Application>,
      userToken: string
    ): Promise<Array<Application>>
    detail(id: string, userToken: string): Promise<Application>
    insertBulk(
      input: Array<Application>,
      userToken: string
    ): Promise<Array<Application>>
    insert(input: Application, userToken: string): Promise<Application>
    upsert(
      id: string,
      input: Application,
      userToken: string
    ): Promise<Application>
    bulkReplace(
      input: Array<Application>,
      userToken: string
    ): Promise<Array<Application>>
    replace(
      id: string,
      input: Application,
      userToken: string
    ): Promise<Application>
    bulkModify(
      input: MassModification<Application>,
      userToken: string
    ): Promise<number>
    modifyWithDiff(
      id: string,
      input: Modification<Application>,
      userToken: string
    ): Promise<EntryChange<Application>>
    modify(
      id: string,
      input: Modification<Application>,
      userToken: string
    ): Promise<Application>
    bulkDelete(
      input: Condition<Application>,
      userToken: string
    ): Promise<number>
    delete(id: string, userToken: string): Promise<void>
    count(input: Condition<Application>, userToken: string): Promise<number>
    groupCount(
      input: GroupCountQuery<Application>,
      userToken: string
    ): Promise<Record<string, number>>
    aggregate(
      input: AggregateQuery<Application>,
      userToken: string
    ): Promise<number | null | undefined>
    groupAggregate(
      input: GroupAggregateQuery<Application>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>>
  }
  uploadFileForRequest(): Promise<UploadInformation>
  getServerHealth(userToken: string): Promise<ServerHealth>
  getMetrics(): Promise<string>
}

export class UserSession {
  constructor(public api: Api, public userToken: string) {}
  getServerHealth(): Promise<ServerHealth> {
    return this.api.getServerHealth(this.userToken)
  }

  uploadFileForRequest(): Promise<UploadInformation> {
    return this.api.uploadFileForRequest()
  }

  getMetrics(): Promise<string> {
    return this.api.getMetrics()
  }

  readonly auth = {
    refreshToken: (): Promise<string> => {
      return this.api.auth.refreshToken(this.userToken)
    },
    getSelf: (): Promise<User> => {
      return this.api.auth.getSelf(this.userToken)
    },
    emailLoginLink: (input: string): Promise<void> => {
      return this.api.auth.emailLoginLink(input)
    },
    emailPINLogin: (input: EmailPinLogin): Promise<string> => {
      return this.api.auth.emailPINLogin(input)
    }
  }

  readonly user = {
    query: (input: Query<User>): Promise<Array<User>> => {
      return this.api.user.query(input, this.userToken)
    },
    detail: (id: string): Promise<User> => {
      return this.api.user.detail(id, this.userToken)
    },
    insertBulk: (input: Array<User>): Promise<Array<User>> => {
      return this.api.user.insertBulk(input, this.userToken)
    },
    insert: (input: User): Promise<User> => {
      return this.api.user.insert(input, this.userToken)
    },
    upsert: (id: string, input: User): Promise<User> => {
      return this.api.user.upsert(id, input, this.userToken)
    },
    bulkReplace: (input: Array<User>): Promise<Array<User>> => {
      return this.api.user.bulkReplace(input, this.userToken)
    },
    replace: (id: string, input: User): Promise<User> => {
      return this.api.user.replace(id, input, this.userToken)
    },
    bulkModify: (input: MassModification<User>): Promise<number> => {
      return this.api.user.bulkModify(input, this.userToken)
    },
    modifyWithDiff: (
      id: string,
      input: Modification<User>
    ): Promise<EntryChange<User>> => {
      return this.api.user.modifyWithDiff(id, input, this.userToken)
    },
    modify: (id: string, input: Modification<User>): Promise<User> => {
      return this.api.user.modify(id, input, this.userToken)
    },
    bulkDelete: (input: Condition<User>): Promise<number> => {
      return this.api.user.bulkDelete(input, this.userToken)
    },
    delete: (id: string): Promise<void> => {
      return this.api.user.delete(id, this.userToken)
    },
    count: (input: Condition<User>): Promise<number> => {
      return this.api.user.count(input, this.userToken)
    },
    groupCount: (
      input: GroupCountQuery<User>
    ): Promise<Record<string, number>> => {
      return this.api.user.groupCount(input, this.userToken)
    },
    aggregate: (
      input: AggregateQuery<User>
    ): Promise<number | null | undefined> => {
      return this.api.user.aggregate(input, this.userToken)
    },
    groupAggregate: (
      input: GroupAggregateQuery<User>
    ): Promise<Record<string, number | null | undefined>> => {
      return this.api.user.groupAggregate(input, this.userToken)
    }
  }

  readonly application = {
    query: (input: Query<Application>): Promise<Array<Application>> => {
      return this.api.application.query(input, this.userToken)
    },
    detail: (id: string): Promise<Application> => {
      return this.api.application.detail(id, this.userToken)
    },
    insertBulk: (input: Array<Application>): Promise<Array<Application>> => {
      return this.api.application.insertBulk(input, this.userToken)
    },
    insert: (input: Application): Promise<Application> => {
      return this.api.application.insert(input, this.userToken)
    },
    upsert: (id: string, input: Application): Promise<Application> => {
      return this.api.application.upsert(id, input, this.userToken)
    },
    bulkReplace: (input: Array<Application>): Promise<Array<Application>> => {
      return this.api.application.bulkReplace(input, this.userToken)
    },
    replace: (id: string, input: Application): Promise<Application> => {
      return this.api.application.replace(id, input, this.userToken)
    },
    bulkModify: (input: MassModification<Application>): Promise<number> => {
      return this.api.application.bulkModify(input, this.userToken)
    },
    modifyWithDiff: (
      id: string,
      input: Modification<Application>
    ): Promise<EntryChange<Application>> => {
      return this.api.application.modifyWithDiff(id, input, this.userToken)
    },
    modify: (
      id: string,
      input: Modification<Application>
    ): Promise<Application> => {
      return this.api.application.modify(id, input, this.userToken)
    },
    bulkDelete: (input: Condition<Application>): Promise<number> => {
      return this.api.application.bulkDelete(input, this.userToken)
    },
    delete: (id: string): Promise<void> => {
      return this.api.application.delete(id, this.userToken)
    },
    count: (input: Condition<Application>): Promise<number> => {
      return this.api.application.count(input, this.userToken)
    },
    groupCount: (
      input: GroupCountQuery<Application>
    ): Promise<Record<string, number>> => {
      return this.api.application.groupCount(input, this.userToken)
    },
    aggregate: (
      input: AggregateQuery<Application>
    ): Promise<number | null | undefined> => {
      return this.api.application.aggregate(input, this.userToken)
    },
    groupAggregate: (
      input: GroupAggregateQuery<Application>
    ): Promise<Record<string, number | null | undefined>> => {
      return this.api.application.groupAggregate(input, this.userToken)
    }
  }
}

export class LiveApi implements Api {
  public constructor(
    public httpUrl: string,
    public socketUrl: string = httpUrl,
    public extraHeaders: Record<string, string> = {}
  ) {}

  uploadFileForRequest(): Promise<UploadInformation> {
    return apiCall(`${this.httpUrl}/upload-early`, undefined, {
      method: "GET"
    }).then((x) => x.json())
  }

  getServerHealth(userToken: string): Promise<ServerHealth> {
    return apiCall(`${this.httpUrl}/meta/health`, undefined, {
      method: "GET",
      headers: userToken
        ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
        : this.extraHeaders
    }).then((x) => x.json())
  }

  getMetrics(): Promise<string> {
    return apiCall(`${this.httpUrl}/metrics/clear`, undefined, {
      method: "GET"
    }).then((x) => x.json())
  }

  readonly auth = {
    refreshToken: (userToken: string): Promise<string> => {
      return apiCall(`${this.httpUrl}/auth/refresh-token`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    getSelf: (userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/auth/self`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    emailLoginLink: (input: string): Promise<void> => {
      return apiCall(`${this.httpUrl}/auth/login-email`, input, {
        method: "POST"
      }).then((x) => undefined)
    },
    emailPINLogin: (input: EmailPinLogin): Promise<string> => {
      return apiCall(`${this.httpUrl}/auth/login-email-pin`, input, {
        method: "POST"
      }).then((x) => x.json())
    }
  }

  readonly user = {
    query: (input: Query<User>, userToken: string): Promise<Array<User>> => {
      return apiCall(`${this.httpUrl}/user/rest/query`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    detail: (id: string, userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insertBulk: (
      input: Array<User>,
      userToken: string
    ): Promise<Array<User>> => {
      return apiCall(`${this.httpUrl}/user/rest/bulk`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insert: (input: User, userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    upsert: (id: string, input: User, userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkReplace: (
      input: Array<User>,
      userToken: string
    ): Promise<Array<User>> => {
      return apiCall(`${this.httpUrl}/user/rest`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    replace: (id: string, input: User, userToken: string): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkModify: (
      input: MassModification<User>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/user/rest/bulk`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modifyWithDiff: (
      id: string,
      input: Modification<User>,
      userToken: string
    ): Promise<EntryChange<User>> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}/delta`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modify: (
      id: string,
      input: Modification<User>,
      userToken: string
    ): Promise<User> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkDelete: (
      input: Condition<User>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/user/rest/bulk-delete`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    delete: (id: string, userToken: string): Promise<void> => {
      return apiCall(`${this.httpUrl}/user/rest/${id}`, undefined, {
        method: "DELETE",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => undefined)
    },
    count: (input: Condition<User>, userToken: string): Promise<number> => {
      return apiCall(`${this.httpUrl}/user/rest/count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupCount: (
      input: GroupCountQuery<User>,
      userToken: string
    ): Promise<Record<string, number>> => {
      return apiCall(`${this.httpUrl}/user/rest/group-count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    aggregate: (
      input: AggregateQuery<User>,
      userToken: string
    ): Promise<number | null | undefined> => {
      return apiCall(`${this.httpUrl}/user/rest/aggregate`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupAggregate: (
      input: GroupAggregateQuery<User>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>> => {
      return apiCall(`${this.httpUrl}/user/rest/group-aggregate`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    }
  }

  readonly application = {
    query: (
      input: Query<Application>,
      userToken: string
    ): Promise<Array<Application>> => {
      return apiCall(`${this.httpUrl}/application/rest/query`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    detail: (id: string, userToken: string): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, undefined, {
        method: "GET",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insertBulk: (
      input: Array<Application>,
      userToken: string
    ): Promise<Array<Application>> => {
      return apiCall(`${this.httpUrl}/application/rest/bulk`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    insert: (input: Application, userToken: string): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    upsert: (
      id: string,
      input: Application,
      userToken: string
    ): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkReplace: (
      input: Array<Application>,
      userToken: string
    ): Promise<Array<Application>> => {
      return apiCall(`${this.httpUrl}/application/rest`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    replace: (
      id: string,
      input: Application,
      userToken: string
    ): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, input, {
        method: "PUT",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkModify: (
      input: MassModification<Application>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/application/rest/bulk`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modifyWithDiff: (
      id: string,
      input: Modification<Application>,
      userToken: string
    ): Promise<EntryChange<Application>> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}/delta`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    modify: (
      id: string,
      input: Modification<Application>,
      userToken: string
    ): Promise<Application> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, input, {
        method: "PATCH",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    bulkDelete: (
      input: Condition<Application>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/application/rest/bulk-delete`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    delete: (id: string, userToken: string): Promise<void> => {
      return apiCall(`${this.httpUrl}/application/rest/${id}`, undefined, {
        method: "DELETE",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => undefined)
    },
    count: (
      input: Condition<Application>,
      userToken: string
    ): Promise<number> => {
      return apiCall(`${this.httpUrl}/application/rest/count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupCount: (
      input: GroupCountQuery<Application>,
      userToken: string
    ): Promise<Record<string, number>> => {
      return apiCall(`${this.httpUrl}/application/rest/group-count`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    aggregate: (
      input: AggregateQuery<Application>,
      userToken: string
    ): Promise<number | null | undefined> => {
      return apiCall(`${this.httpUrl}/application/rest/aggregate`, input, {
        method: "POST",
        headers: userToken
          ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
          : this.extraHeaders
      }).then((x) => x.json())
    },
    groupAggregate: (
      input: GroupAggregateQuery<Application>,
      userToken: string
    ): Promise<Record<string, number | null | undefined>> => {
      return apiCall(
        `${this.httpUrl}/application/rest/group-aggregate`,
        input,
        {
          method: "POST",
          headers: userToken
            ? {...this.extraHeaders, Authorization: `Bearer ${userToken}`}
            : this.extraHeaders
        }
      ).then((x) => x.json())
    }
  }
}
