/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {Box, Typography} from "@mui/material"
import {Address, Application, User} from "api/sdk"
import FormSection from "components/FormSection"
import {LabeledValue} from "components/LabeledValue"
import React, {FC} from "react"
import {
  businessFormLabels,
  existingStateLabels,
  signupFormStatusLabels,
  spouseParticipationLabels
} from "utils/helpers/enumLabels"

function addressRenderer(address: Address) {
  return (
    <Box>
      {address.line1 && (
        <Typography variant="body1">{address.line1}</Typography>
      )}
      {address.line2 && (
        <Typography variant="body1">{address.line2}</Typography>
      )}
      <Typography variant="body1">
        {`${address.city ?? "–"}, ${address.state ?? "–"} ${
          address.postalCode ?? "–"
        }`}
      </Typography>
    </Box>
  )
}

function dateRenderer(date: string) {
  return (
    <Typography variant="body1">
      {new Date(date).toLocaleDateString()}
    </Typography>
  )
}

function dateTimeRenderer(date: string) {
  return (
    <Typography variant="body1">{new Date(date).toLocaleString()}</Typography>
  )
}

export interface ApplicationPreviewProps {
  application: Application
  user: User
}

export const ApplicationPreview: FC<ApplicationPreviewProps> = ({
  application,
  user
}) => {
  const {
    trustee,
    business,
    originalPlan,
    existing,
    spouse,
    spouseTrusteeInfo,
    desiredTrustName,
    review,
    readyForReview,
    stripe,
    signature,
    solera
  } = application

  return (
    <>
      <Typography variant="h2" sx={{mb: 3}}>
        Application Progress
      </Typography>

      <FormSection title="Trustee" spacing={2} disableTopPadding>
        {!trustee ? (
          <Typography variant="body1" fontStyle="italic">
            Trustee info not started
          </Typography>
        ) : (
          <>
            <LabeledValue
              label="Name"
              value={`${trustee.givenName} ${trustee.middleName} ${trustee.surname}`}
            />
            <LabeledValue label="Email" value={user.email} />
            <LabeledValue label="Phone" value={trustee.phone} />
            <LabeledValue
              label="Birthday"
              value={trustee.birthday}
              valueRenderer={dateRenderer}
            />
            <LabeledValue
              label="Social Security Number"
              value={trustee.socialSecurityNumber}
            />
            <LabeledValue
              label="Address"
              value={trustee.address}
              valueRenderer={addressRenderer}
            />
          </>
        )}
      </FormSection>

      <FormSection title="Business" spacing={2}>
        {!business ? (
          <Typography variant="body1" fontStyle="italic">
            Business info not started
          </Typography>
        ) : (
          <>
            <LabeledValue label="Name" value={business.name} />
            <LabeledValue label="Phone" value={business.phone} />
            <LabeledValue
              label="Started At"
              value={business.startedAt}
              valueRenderer={dateRenderer}
            />
            <LabeledValue
              label="Address"
              value={business.address}
              valueRenderer={addressRenderer}
            />
            <LabeledValue
              label="State of Formation"
              value={business.stateOfFormation}
            />
            <LabeledValue
              label="Business Form"
              value={business.businessForm}
              valueRenderer={(businessForm) => businessFormLabels[businessForm]}
            />
            <LabeledValue
              label="EIN / SSN"
              value={business.entityIdentificationNumber}
            />
            <LabeledValue
              label="NAICS Code / Description"
              value={business.naicsCodeOrDescription}
            />
            <LabeledValue
              label="FY End Date"
              value={business.fiscalYearEnd}
              valueRenderer={(fiscalYearEnd) => (
                <Typography variant="body1">
                  {fiscalYearEnd.month} / {fiscalYearEnd.day}
                </Typography>
              )}
            />
          </>
        )}
      </FormSection>

      <FormSection title="401k Plan" spacing={2}>
        {!desiredTrustName && !originalPlan && !existing ? (
          <Typography variant="body1" fontStyle="italic">
            Plan info not started
          </Typography>
        ) : (
          <>
            <LabeledValue label="Desired Trust Name" value={desiredTrustName} />
            <LabeledValue
              label="Existing Plan"
              value={existing}
              valueRenderer={(existingState) =>
                existingStateLabels[existingState]
              }
            />
            <LabeledValue
              label="Original Plan Date"
              value={originalPlan?.originalPlanDate}
              valueRenderer={dateRenderer}
            />
            <LabeledValue
              label="Original Trust EIN"
              value={originalPlan?.originalTrustEin}
            />
          </>
        )}
      </FormSection>

      <FormSection title="Spouse" spacing={2}>
        {!spouse && !spouseTrusteeInfo ? (
          <Typography variant="body1" fontStyle="italic">
            Spouse info not started
          </Typography>
        ) : (
          <>
            <LabeledValue
              label="Spouse Participation"
              value={spouse}
              valueRenderer={(spouseParticipation) =>
                spouseParticipationLabels[spouseParticipation]
              }
            />
            <LabeledValue
              label="Spouse Name"
              value={spouseTrusteeInfo?.fullLegalName}
            />
            <LabeledValue
              label="Spouse Email"
              value={spouseTrusteeInfo?.email}
            />
          </>
        )}
      </FormSection>

      <FormSection title="Payment" spacing={2}>
        {!stripe ? (
          <Typography variant="body1" fontStyle="italic">
            Payment not yet completed
          </Typography>
        ) : (
          <>
            <LabeledValue
              label="Subscription Status"
              value={stripe.active}
              valueRenderer={(active) => (active ? "Active" : "Inactive")}
            />
            <LabeledValue
              label="Stripe Subscription ID"
              value={stripe.subscriptionId}
            />
          </>
        )}
      </FormSection>

      <FormSection title="Signed Documents" spacing={2}>
        {!signature ? (
          <Typography variant="body1" fontStyle="italic">
            Documents not yet signed
          </Typography>
        ) : (
          <>
            <LabeledValue
              label="Signature Status"
              value={signature.signed}
              valueRenderer={(active) => (active ? "Signed" : "Not Signed")}
            />
            <LabeledValue label="Signature ID" value={signature.id} />
          </>
        )}
      </FormSection>

      <FormSection title="Review" spacing={2}>
        <LabeledValue
          label="Ready for Review"
          value={readyForReview}
          valueRenderer={(active) => (active ? "Yes" : "No")}
        />

        {!review ? (
          <Typography variant="body1" fontStyle="italic">
            Review not completed
          </Typography>
        ) : (
          <>
            <LabeledValue
              label="Review Status"
              value={review.status}
              valueRenderer={(status) => signupFormStatusLabels[status]}
            />
            <LabeledValue
              label="Reviewed At"
              value={review.at}
              valueRenderer={dateTimeRenderer}
            />
            <LabeledValue label="Review Notes" value={review.notes} />
          </>
        )}
      </FormSection>
    </>
  )
}
