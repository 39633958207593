import {
  makeFormikDateTimePickerProps,
  makeFormikTextFieldProps
} from "@lightningkite/mui-lightning-components"
import {ArrowDropDown} from "@mui/icons-material"
import {MenuItem, Stack, TextField} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import dayjs from "dayjs"
import {useFormik} from "formik"
import React, {FC} from "react"
import {stateCodeOptions} from "utils/constants"
import {emptyTrusteeInfo} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"

const validationSchema = yup.object().shape({
  givenName: yup.string().required("Required"),
  surname: yup.string().required("Required"),
  address1: yup.string().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  postalCode: yup.string().required("Required"),
  country: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  ssn: yup.string().required("Required"),
  dateOfBirth: yup.date().required("Required")
})

const Trustee: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props

  const formik = useFormik({
    initialValues: {
      givenName: application.trustee?.givenName ?? "",
      middleName: application.trustee?.middleName ?? "",
      surname: application.trustee?.surname ?? "",
      address1: application.trustee?.address?.line1 ?? "",
      address2: application.trustee?.address?.line2 ?? "",
      city: application.trustee?.address?.city ?? "",
      state: application.trustee?.address?.state ?? "",
      postalCode: application.trustee?.address?.postalCode ?? "",
      country: application.trustee?.address?.country ?? "United States",
      phone: application.trustee?.phone ?? "",
      ssn: application.trustee?.socialSecurityNumber ?? "",
      dateOfBirth: application.trustee?.birthday
        ? new Date(application.trustee?.birthday)
        : null
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      return saveProgress({
        trustee: {
          ...(application.trustee ?? emptyTrusteeInfo),
          givenName: values.givenName,
          middleName: values.middleName,
          surname: values.surname,
          address: {
            line1: values.address1,
            line2: values.address2,
            city: values.city,
            state: values.state,
            postalCode: values.postalCode,
            country: values.country
          },
          phone: values.phone,
          socialSecurityNumber: values.ssn,
          birthday: values.dateOfBirth?.toISOString().split("T")[0]
        }
      })
        .then(() => {
          resetForm({values})
          nextStep()
        })
        .catch(() => alert("Error saving"))
    }
  })

  return (
    <>
      <FormSection title="Your Self" disableTopPadding>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="First Name"
            {...makeFormikTextFieldProps(formik, "givenName")}
          />
          <TextField
            fullWidth
            label="Middle Name"
            {...makeFormikTextFieldProps(formik, "middleName")}
          />
          <TextField
            fullWidth
            label="Last Name"
            {...makeFormikTextFieldProps(formik, "surname")}
          />
        </Stack>

        <TextField
          label="Phone"
          {...makeFormikTextFieldProps(formik, "phone")}
        />
        <TextField
          label="Social Security Number"
          {...makeFormikTextFieldProps(formik, "ssn")}
        />
        <DatePicker
          label="Birthday"
          {...makeFormikDateTimePickerProps(formik, "dateOfBirth")}
          minDate={dayjs().subtract(120, "year")}
          maxDate={dayjs()}
        />
      </FormSection>

      <FormSection title="Address">
        <TextField
          label="Street Address"
          {...makeFormikTextFieldProps(formik, "address1")}
        />
        <TextField
          label="Address Line 2"
          {...makeFormikTextFieldProps(formik, "address2")}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="City"
            {...makeFormikTextFieldProps(formik, "city")}
          />
          <TextField
            fullWidth
            select
            label="State"
            {...makeFormikTextFieldProps(formik, "state")}
          >
            {stateCodeOptions.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Zip"
            {...makeFormikTextFieldProps(formik, "postalCode")}
          />
        </Stack>
        <TextField
          label="Country"
          disabled
          {...makeFormikTextFieldProps(formik, "country")}
        />
      </FormSection>

      <AutoLoadingButton
        endIcon={<ArrowDropDown />}
        variant="contained"
        onClick={formik.submitForm}
        sx={{mt: 4}}
      >
        Save and Continue
      </AutoLoadingButton>
    </>
  )
}

export default Trustee
