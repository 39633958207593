import {makeObjectModification} from "@lightningkite/lightning-server-simplified"
import {Check} from "@mui/icons-material"
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Stack,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Typography
} from "@mui/material"
import {Application, SignupFormStatus} from "api/sdk"
import {AuthContext} from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import Loading from "components/Loading"
import React, {FC, useContext, useEffect, useState} from "react"
import {getEmptyApplication} from "utils/helpers/helpers"
import {getLastCompletedStep, StepIndex, steps} from "utils/helpers/stepHelpers"

export interface FormStepContentProps {
  application: Application
  saveProgress: (newValues: Partial<Application>) => Promise<void>
  nextStep: () => void
  refreshApplication: () => Promise<void>
}

const UserApplicationPage: FC = () => {
  const {session, currentUser, logout} = useContext(AuthContext)

  const [lastCompletedStep, setLastCompletedStep] = useState<
    StepIndex | undefined
  >()
  const [activeStep, setActiveStep] = useState<StepIndex | undefined>()
  const [applications, setApplications] = useState<
    Application[] | null | undefined
  >()
  const [currentApplication, setCurrentApplication] =
    useState<Application | null>(null)

  const saveProgress = async (newValues: Partial<Application>) => {
    if (!currentApplication) {
      throw new Error("Application is not started")
    }

    const modification = makeObjectModification(currentApplication, newValues)

    if (modification === null) {
      setCurrentApplication({...currentApplication})
      return
    }

    await session.application
      .modify(currentApplication._id, modification)
      .then((a) => setCurrentApplication(a))
  }

  const refreshApplication = async (): Promise<void> => {
    if (!currentApplication) {
      throw new Error("Application is not started")
    }

    await session.application
      .detail(currentApplication._id)
      .then(setCurrentApplication)
      .catch(() => setCurrentApplication(null))
  }

  const nextStep = () => {
    if (activeStep === undefined) {
      throw new Error("Active step is not initialized")
    }

    setActiveStep(activeStep + 1)
  }

  useEffect(() => {
    session.application
      .query({condition: {owner: {Equal: currentUser._id}}})
      .then(setApplications)
      .catch(() => setApplications(null))
  }, [])

  useEffect(() => {
    if (!currentApplication) {
      return
    }

    const lastCompletedStep = getLastCompletedStep(currentApplication) ?? -1
    setLastCompletedStep(lastCompletedStep)

    if (activeStep === undefined) {
      setActiveStep(lastCompletedStep + 1)
    }
  }, [currentApplication])

  useEffect(() => {
    if (applications?.length && currentApplication === null) {
      setCurrentApplication(applications[0])
    }
  }, [applications])

  return (
    <>
      <Stack direction="row" spacing={2} sx={{mb: 2}}>
        <Button onClick={logout}>Log Out</Button>
      </Stack>

      <Typography variant="h1" mb={4}>
        Solo 401k Application
      </Typography>

      {(() => {
        if (applications === undefined) {
          return <Loading />
        }

        if (applications === null) {
          return (
            <Alert severity="error" sx={{mt: 4}}>
              <AlertTitle>Error Loading Your Applications</AlertTitle>
              Please refresh the page or try again later.
            </Alert>
          )
        }

        if (!currentApplication) {
          return (
            <>
              <Stack spacing={2}>
                <Typography variant="h2">Confirm Eligibility</Typography>
                <Typography variant="body1">
                  To be eligible, you must have some self-employment activity.
                  This self-employment activity may be on a part time basis. It
                  is also permissible to work a full time job in addition to
                  your self-employment.
                </Typography>
                <Typography variant="body1">
                  Secondly, you will need to confirm that you do not have any
                  full time non-owner W2 employees. Part time employees (under
                  500 hours per year indefinitely or 1,000 hours per year for
                  fewer than 3 years), 1099 independent contractors, a spouse,
                  and other employees who are owners in the business are ok.
                </Typography>
              </Stack>

              <AutoLoadingButton
                startIcon={<Check />}
                onClick={() =>
                  session.application
                    .insert(getEmptyApplication(currentUser))
                    .then((application) => setCurrentApplication(application))
                    .catch(() => alert("Error creating application"))
                }
                variant="contained"
                sx={{mt: 3}}
              >
                Confirm and Start Application
              </AutoLoadingButton>
            </>
          )
        }

        if (lastCompletedStep === undefined || activeStep === undefined) {
          return <Loading />
        }

        return (
          <Stepper
            nonLinear
            activeStep={activeStep}
            orientation="vertical"
            sx={{mt: 4}}
          >
            {Object.values(steps).map((step, index: StepIndex) => {
              let isStepDisabled = index > lastCompletedStep + 1

              if (
                index > StepIndex.REVIEW &&
                currentApplication.review?.status !== SignupFormStatus.Approved
              ) {
                isStepDisabled = true
              }

              if (
                index > StepIndex.TERMS_OF_SERVICE &&
                !currentApplication.signature?.signed
              ) {
                isStepDisabled = true
              }

              return (
                <Step key={index} completed={index <= lastCompletedStep}>
                  <StepButton
                    onClick={() => setActiveStep(index)}
                    disabled={isStepDisabled}
                    optional={index === activeStep ? step.subtitle : undefined}
                  >
                    <Typography variant="h2">{step.title}</Typography>
                  </StepButton>
                  <StepContent>
                    <Card sx={{mt: 2, maxWidth: 650}}>
                      <CardContent>
                        <step.content
                          saveProgress={saveProgress}
                          application={currentApplication}
                          refreshApplication={refreshApplication}
                          nextStep={nextStep}
                        />
                      </CardContent>
                    </Card>
                  </StepContent>
                </Step>
              )
            })}
          </Stepper>
        )
      })()}
    </>
  )
}

export default UserApplicationPage
