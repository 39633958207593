import {RestDataTable} from "@lightningkite/mui-lightning-components"
import {Container} from "@mui/material"
import {AuthContext} from "App"
import PageHeader from "components/PageHeader"
import React, {FC, useContext} from "react"
import {useNavigate} from "react-router-dom"
import {getCurrentStepLabel} from "utils/helpers/stepHelpers"

export interface ApplicationIndexProps {
  onlyNeedsReview?: boolean
}

const ApplicationIndex: FC<ApplicationIndexProps> = ({
  onlyNeedsReview = false
}) => {
  const {session} = useContext(AuthContext)
  const navigate = useNavigate()

  return (
    <Container maxWidth="md">
      <PageHeader
        title={onlyNeedsReview ? "Needs Review" : "All Applications"}
      />

      <RestDataTable
        onRowClick={(application) =>
          navigate(
            onlyNeedsReview
              ? `/needs-review/${application._id}`
              : `/applications/${application._id}`
          )
        }
        restEndpoint={session.application}
        additionalQueryConditions={
          onlyNeedsReview ? [{readyForReview: {Equal: true}}] : []
        }
        columns={[
          {
            field: "business",
            headerName: "Business",
            flex: 1,
            valueGetter: ({row}) => row.business?.name,
            renderCell: ({value}) => value ?? <i>Not provided</i>,
            sortable: false
          },
          {
            field: "trustee",
            headerName: "Trustee",
            flex: 1,
            valueGetter: ({row}) =>
              `${row.trustee?.givenName ?? ""} ${row.trustee?.surname ?? ""}`,
            renderCell: ({value}) =>
              value !== " " ? value : <i>Not provided</i>,
            sortable: false
          },
          {
            field: "status",
            headerName: "Current Step",
            valueGetter: ({row}) => getCurrentStepLabel(row),
            width: 200,
            sortable: false
          }
        ]}
      />
    </Container>
  )
}

export default ApplicationIndex
