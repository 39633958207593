import {makeObjectModification} from "@lightningkite/lightning-server-simplified"
import {makeFormikTextFieldProps} from "@lightningkite/mui-lightning-components"
import {LoadingButton} from "@mui/lab"
import {TextField} from "@mui/material"
import {Application, SignupFormStatus} from "api/sdk"
import {AuthContext} from "App"
import FormSection from "components/FormSection"
import {makeFormikRadioInputProps, RadioInput} from "components/RadioInput"
import {useFormik} from "formik"
import React, {FC, useContext} from "react"
import {signupFormStatusLabels} from "utils/helpers/enumLabels"
import * as yup from "yup"

const validationSchema = yup.object().shape({
  status: yup.string().nullable().required("Required")
})

export interface ApplicationReviewProps {
  application: Application
  refreshApplication: () => Promise<void>
}

export const ApplicationReview: FC<ApplicationReviewProps> = (props) => {
  const {application, refreshApplication} = props
  const {session} = useContext(AuthContext)

  const formik = useFormik({
    initialValues: {
      status: application.review?.status ?? null,
      notes: application.review?.notes ?? ""
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      return session.application
        .modify(
          application._id,
          makeObjectModification(application, {
            readyForReview: false,
            review: {
              at: new Date().toISOString(),
              status: values.status as SignupFormStatus,
              notes: values.notes
            }
          })
        )
        .then(() => {
          resetForm({values})
          refreshApplication()
        })
        .catch(() => alert("Error saving"))
    }
  })

  return (
    <>
      <FormSection
        title="Required Review"
        subtitle="This application requires review before the applicant can sign documents"
        disableTopPadding
      >
        <RadioInput
          label="Selected Status"
          options={Object.keys(SignupFormStatus).map((key) => ({
            value: key,
            label: signupFormStatusLabels[key as SignupFormStatus]
          }))}
          {...makeFormikRadioInputProps(formik, "status")}
        />

        <TextField
          multiline
          label="Review Notes"
          {...makeFormikTextFieldProps(formik, "notes")}
          helperText="These notes will be visible to the applicant"
        />

        <LoadingButton
          onClick={() => {
            formik.submitForm()
          }}
          variant="contained"
          color="primary"
          loading={formik.isSubmitting}
          style={{alignSelf: "end"}}
        >
          Submit Review
        </LoadingButton>
      </FormSection>
    </>
  )
}
