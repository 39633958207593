import {ApplicationDetail} from "pages/ApplicationDetail"
import ApplicationIndex from "pages/ApplicationIndex"
import {UserDetail} from "pages/UserDetail"
import UserIndex from "pages/UserIndex"
import React, {FC} from "react"
import {Navigate, Route, Routes} from "react-router-dom"

const AdminRoutes: FC = () => {
  return (
    <Routes>
      <Route>
        <Route
          path="/needs-review"
          element={<ApplicationIndex onlyNeedsReview />}
        />
        <Route
          path="/needs-review/:applicationId"
          element={<ApplicationDetail />}
        />

        <Route path="/applications" element={<ApplicationIndex />} />
        <Route
          path="/applications/:applicationId"
          element={<ApplicationDetail />}
        />

        <Route path="/users" element={<UserIndex />} />
        <Route path="/users/:userId" element={<UserDetail />} />
        <Route
          path="/users/:userId/:applicationId"
          element={<ApplicationDetail />}
        />

        {/* If page doesn't exist, redirect to home */}
        <Route path="*" element={<Navigate to="/needs-review" replace />} />
      </Route>
    </Routes>
  )
}

export default AdminRoutes
