import {ArrowDropDown, Check, Refresh} from "@mui/icons-material"
import {Alert, AlertTitle, Button, Typography} from "@mui/material"
import {SignupFormStatus} from "api/sdk"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import React, {FC} from "react"
import {FormStepContentProps} from "../UserApplicationPage"

const Review: FC<FormStepContentProps> = (props) => {
  const {refreshApplication, application, nextStep, saveProgress} = props

  const RefreshButton = () => (
    <AutoLoadingButton
      size="small"
      startIcon={<Refresh />}
      color="inherit"
      onClick={() =>
        refreshApplication().catch(() => alert("Failed to refresh"))
      }
    >
      Refresh
    </AutoLoadingButton>
  )

  return (
    <FormSection
      disableTopPadding
      title="Application Review"
      subtitle="We will manually review your application and update you when the status changes"
    >
      {(() => {
        if (!application.review) {
          return (
            <Alert severity="info" action={<RefreshButton />}>
              <AlertTitle>Review in Progress</AlertTitle>
              We&apos;re reviewing your application. You will receive an email
              when it&apos;s complete!
            </Alert>
          )
        }

        if (application.review.status === SignupFormStatus.Rejected) {
          return (
            <Alert severity="error">
              <AlertTitle>Application Rejected</AlertTitle>
              {application.review.notes || "Your application has been rejected"}
            </Alert>
          )
        }

        if (
          application.review.status === SignupFormStatus.ChangesRequested &&
          !application.readyForReview
        ) {
          return (
            <>
              <Alert severity="warning">
                <AlertTitle>Changes Requested</AlertTitle>
                {application.review.notes ||
                  "Changes have been requested to your application"}
              </Alert>
              <AutoLoadingButton
                sx={{mt: 4, alignSelf: "flex-start"}}
                startIcon={<Check />}
                onClick={() =>
                  saveProgress({
                    readyForReview: true
                  })
                }
              >
                I have made the requested changes
              </AutoLoadingButton>
            </>
          )
        }

        if (
          application.review.status === SignupFormStatus.ChangesRequested &&
          application.readyForReview
        ) {
          return (
            <>
              <Alert severity="info" action={<RefreshButton />}>
                <AlertTitle>Reviewing Your Changes...</AlertTitle>
                <Typography variant="body2">
                  We are currently reviewing the changes we requested to your
                  application
                </Typography>
                {application.review.notes && (
                  <Typography variant="body2" sx={{mt: 1}}>
                    {application.review.notes}
                  </Typography>
                )}
              </Alert>
            </>
          )
        }

        return (
          <>
            <Alert severity="success">
              <AlertTitle>Approved!</AlertTitle>
              {application.review.notes}
            </Alert>

            <Button
              endIcon={<ArrowDropDown />}
              variant="contained"
              onClick={() => nextStep()}
              sx={{alignSelf: "flex-start"}}
            >
              Continue
            </Button>
          </>
        )
      })()}
    </FormSection>
  )
}
export default Review
