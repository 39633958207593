import {areValuesSame} from "@lightningkite/lightning-server-simplified"
import {
  HoverHelp,
  makeFormikCheckboxProps,
  makeFormikDateTimePickerProps,
  makeFormikTextFieldProps
} from "@lightningkite/mui-lightning-components"
import {ArrowDropDown} from "@mui/icons-material"
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Stack,
  TextField
} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import dayjs from "dayjs"
import {useFormik} from "formik"
import React, {FC} from "react"
import {stateCodeOptions} from "utils/constants"
import {emptyBusinessInfo} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"

const validationSchema = yup.object().shape({
  businessName: yup.string().required("Required"),
  businessPhone: yup.string().required("Required"),
  startedAt: yup.date().required("Required"),
  sameAsTrustee: yup.boolean(),
  address1: yup.string().when("sameAsTrustee", {
    is: false,
    then: yup.string().required("Required")
  }),
  city: yup.string().when("sameAsTrustee", {
    is: false,
    then: yup.string().required("Required")
  }),
  state: yup.string().when("sameAsTrustee", {
    is: false,
    then: yup.string().required("Required")
  }),
  postalCode: yup.string().when("sameAsTrustee", {
    is: false,
    then: yup.string().required("Required")
  }),
  country: yup.string().when("sameAsTrustee", {
    is: false,
    then: yup.string().required("Required")
  })
})

const BusinessInfo: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props

  const formik = useFormik({
    initialValues: {
      businessName: application.business?.name ?? "",
      businessPhone: application.business?.phone ?? "",
      startedAt: application.business?.startedAt
        ? new Date(application.business?.startedAt)
        : null,
      sameAsTrustee: areValuesSame(
        application.trustee?.address,
        application.business?.address
      ),
      address1: application.business?.address?.line1 ?? "",
      address2: application.business?.address?.line2 ?? "",
      city: application.business?.address?.city ?? "",
      state: application.business?.address?.state ?? "",
      postalCode: application.business?.address?.postalCode ?? "",
      country: application.business?.address?.country ?? "United States"
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      return saveProgress({
        business: {
          ...(application.business ?? emptyBusinessInfo),
          name: values.businessName,
          phone: values.businessPhone,
          startedAt: (values.startedAt as Date).toISOString().split("T")[0],
          address: values.sameAsTrustee
            ? application.trustee?.address
            : {
                line1: values.address1,
                line2: values.address2,
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.postalCode
              }
        }
      })
        .then(() => {
          resetForm({values})
          nextStep()
        })
        .catch(() => alert("Error saving"))
    }
  })

  return (
    <>
      <FormSection
        title="Your Business"
        subtitle="Your business name is your full name if you have a sole proprietorship and have not formed an LLC or filed a DBA."
        disableTopPadding
      >
        <HoverHelp description="The business name can be your full name if you have a sole proprietorship (this is the default business type if you have not formed an LLC, Corporation, etc.) and have not filed a DBA (doing business as) or fictitious name certificate. A lot of our plans are set up for sole proprietors using their personal name.">
          <TextField
            fullWidth
            label="Business Name"
            {...makeFormikTextFieldProps(formik, "businessName")}
          />
        </HoverHelp>

        <TextField
          label="Business Phone"
          {...makeFormikTextFieldProps(formik, "businessPhone")}
        />

        <HoverHelp
          enableWrapper
          description="The business start date can be approximate and can be as recent as today. There is no minimum age for a business or self-employment activity to adopt a plan."
        >
          <DatePicker
            label="Business Start Date"
            {...makeFormikDateTimePickerProps(formik, "startedAt")}
            minDate={dayjs().subtract(120, "year")}
            maxDate={dayjs()}
          />
        </HoverHelp>
      </FormSection>

      <FormSection title="Business Address">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox {...makeFormikCheckboxProps(formik, "sameAsTrustee")} />
            }
            label="Same as trustee address"
          />
        </FormGroup>

        {!formik.values.sameAsTrustee && (
          <>
            <TextField
              label="Street Address"
              {...makeFormikTextFieldProps(formik, "address1")}
            />
            <TextField
              label="Address Line 2"
              {...makeFormikTextFieldProps(formik, "address2")}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="City"
                {...makeFormikTextFieldProps(formik, "city")}
              />
              <TextField
                fullWidth
                select
                label="State"
                {...makeFormikTextFieldProps(formik, "state")}
              >
                {stateCodeOptions.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                label="Zip"
                {...makeFormikTextFieldProps(formik, "postalCode")}
              />
            </Stack>
            <TextField
              label="Country"
              disabled
              {...makeFormikTextFieldProps(formik, "country")}
            />
          </>
        )}
      </FormSection>

      <AutoLoadingButton
        endIcon={<ArrowDropDown />}
        variant="contained"
        onClick={formik.submitForm}
        sx={{mt: 4}}
      >
        Save and Continue
      </AutoLoadingButton>
    </>
  )
}

export default BusinessInfo
