import {ArrowDropDown, ArrowRight, Refresh} from "@mui/icons-material"
import {Alert, Button} from "@mui/material"
import {AuthContext} from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import React, {FC, useContext} from "react"
import {LocalStorageKey} from "utils/constants"
import {FormStepContentProps} from "../UserApplicationPage"

const Payment: FC<FormStepContentProps> = (props) => {
  const {refreshApplication, application, nextStep} = props
  const {session} = useContext(AuthContext)

  return (
    <>
      <FormSection
        disableTopPadding
        title="Setup Payment"
        subtitle="We collect payments through Stripe, a secure 3rd party payment processor. Return to this page after you have completed the payment to continue."
      >
        {!application.stripe?.active ? (
          <>
            <Alert
              severity="warning"
              action={
                <AutoLoadingButton
                  size="small"
                  startIcon={<Refresh />}
                  color="inherit"
                  onClick={() =>
                    refreshApplication().catch(() => alert("Failed to refresh"))
                  }
                >
                  Refresh
                </AutoLoadingButton>
              }
            >
              Not Paid
            </Alert>

            <Button
              variant="contained"
              href={`${
                localStorage.getItem(LocalStorageKey.BACKEND_URL) ?? ""
              }/application/payment/${application._id}?jwt=${
                session.userToken
              }`}
              target="_blank"
              endIcon={<ArrowRight />}
              sx={{alignSelf: "flex-start"}}
            >
              Open Payment Page
            </Button>
          </>
        ) : (
          <>
            <Alert severity="success">Payment Complete!</Alert>

            <Button
              endIcon={<ArrowDropDown />}
              variant="contained"
              disabled={!application?.stripe?.active}
              onClick={() => nextStep()}
              sx={{alignSelf: "flex-start"}}
            >
              Continue
            </Button>
          </>
        )}
      </FormSection>
    </>
  )
}
export default Payment
