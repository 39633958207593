import {Application, SignupFormStatus, SoleraStatus} from "api/sdk"
import BusinessForm from "pages/UserApplicationPage/Steps/BusinessForm"
import BusinessInfo from "pages/UserApplicationPage/Steps/BusinessInfo"
import Finalize from "pages/UserApplicationPage/Steps/Finalize"
import NotImplemented from "pages/UserApplicationPage/Steps/NotImplemented"
import Payment from "pages/UserApplicationPage/Steps/Payment"
import PlanInfo from "pages/UserApplicationPage/Steps/PlanInfo"
import Review from "pages/UserApplicationPage/Steps/Review"
import TermsOfService from "pages/UserApplicationPage/Steps/TermsOfService"
import Trustee from "pages/UserApplicationPage/Steps/Trustee"
import {FormStepContentProps} from "pages/UserApplicationPage/UserApplicationPage"
import {FC} from "react"

export enum StepIndex {
  TRUSTEE_INFORMATION,
  BUSINESS_INFORMATION,
  BUSINESS_FORM,
  PLAN_INFORMATION,
  PAYMENT,
  REVIEW,
  TERMS_OF_SERVICE,
  SOLERA
}

export const StepIndexNames: Record<StepIndex, string> = {
  [StepIndex.TRUSTEE_INFORMATION]: "Trustee Information",
  [StepIndex.BUSINESS_INFORMATION]: "Business Information",
  [StepIndex.BUSINESS_FORM]: "Business Form",
  [StepIndex.PLAN_INFORMATION]: "Plan Information",
  [StepIndex.PAYMENT]: "Payment",
  [StepIndex.REVIEW]: "Review",
  [StepIndex.TERMS_OF_SERVICE]: "Terms of Service",
  [StepIndex.SOLERA]: "Solera"
}

export interface FormStep {
  title: string
  subtitle?: string
  content: FC<FormStepContentProps>
}

export const steps: Record<StepIndex, FormStep> = {
  [StepIndex.TRUSTEE_INFORMATION]: {
    title: "Trustee Information",
    subtitle:
      "Enter your personal information as the trustee, the person who will be in control of the plan.",
    content: Trustee
  },
  [StepIndex.BUSINESS_INFORMATION]: {
    title: "Business Information",
    subtitle:
      "Enter the basic information for your business or self-employment activity here",
    content: BusinessInfo
  },
  [StepIndex.BUSINESS_FORM]: {
    title: "Business Form",
    content: BusinessForm
  },
  [StepIndex.PLAN_INFORMATION]: {
    title: "Plan Information",
    content: PlanInfo
  },
  [StepIndex.PAYMENT]: {
    title: "Payment",
    content: Payment
  },
  [StepIndex.REVIEW]: {
    title: "Review",
    content: Review
  },
  [StepIndex.TERMS_OF_SERVICE]: {
    title: "Terms of Service",
    content: TermsOfService
  },
  [StepIndex.SOLERA]: {
    title: "Finalize",
    content: Finalize
  }
}

export const getLastCompletedStep = (
  application: Application
): StepIndex | null => {
  const stepCompletion: Record<StepIndex, boolean> = {
    [StepIndex.TRUSTEE_INFORMATION]:
      !!application.trustee &&
      Object.values(application.trustee).every((value) => value !== null),
    [StepIndex.BUSINESS_INFORMATION]:
      !!application.business?.name &&
      !!application.business.phone &&
      !!application.business.startedAt,
    [StepIndex.BUSINESS_FORM]:
      !!application.business?.businessForm &&
      !!application.business.stateOfFormation &&
      !!application.business.entityIdentificationNumber &&
      !!application.business.fiscalYearEnd &&
      !!application.business.naicsCodeOrDescription,
    [StepIndex.PLAN_INFORMATION]:
      !!application.existing &&
      !!application.desiredTrustName &&
      !!application.spouse,
    [StepIndex.PAYMENT]: !!application.stripe,
    [StepIndex.REVIEW]:
      application.review?.status === SignupFormStatus.Approved,
    [StepIndex.TERMS_OF_SERVICE]: !!application.signature?.signed,
    [StepIndex.SOLERA]: application.solera?.status === SoleraStatus.APPROVED
  }

  // Null if no steps are completed
  if (Object.values(stepCompletion).every((value) => !value)) {
    return null
  }

  // Find the first step where the next step is not completed
  return Number(
    Object.keys(stepCompletion).find(
      (index) => !stepCompletion[(+index + 1) as StepIndex]
    )
  )
}

export const getCurrentStepLabel = (application: Application): string => {
  const lastCompletedStep = getLastCompletedStep(application) ?? -1
  if (lastCompletedStep === StepIndex.SOLERA) return "Completed"
  return `${StepIndexNames[(lastCompletedStep + 1) as StepIndex]}`
}
