import {ArrowDropDown, Refresh} from "@mui/icons-material"
import {Alert, AlertTitle, Button} from "@mui/material"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import React, {FC} from "react"
import {FormStepContentProps} from "../UserApplicationPage"

const TermsOfService: FC<FormStepContentProps> = (props) => {
  const {application, nextStep, refreshApplication} = props
  return (
    <>
      <FormSection disableTopPadding title="Sign Digital Documents">
        {!application.signature?.signed ? (
          <Alert
            severity="warning"
            action={
              <AutoLoadingButton
                size="small"
                startIcon={<Refresh />}
                color="inherit"
                onClick={() =>
                  refreshApplication().catch(() => alert("Failed to refresh"))
                }
              >
                Refresh
              </AutoLoadingButton>
            }
          >
            <AlertTitle>Waiting for signed documents</AlertTitle>A link has been
            sent to your email address with instructions for digitally signing
            our terms of service
          </Alert>
        ) : (
          <>
            <Alert severity="success">
              <AlertTitle>Complete!</AlertTitle>
              We have received your signed documents
            </Alert>
            <Button
              endIcon={<ArrowDropDown />}
              variant="contained"
              disabled={!application.signature?.signed}
              onClick={nextStep}
              sx={{alignSelf: "flex-start"}}
            >
              Continue
            </Button>
          </>
        )}
      </FormSection>
    </>
  )
}
export default TermsOfService
