import {generateApplicationsForUsers} from "./mocks/applications"
import {generateUsers} from "./mocks/users"
import {Application, User} from "./sdk"

export interface MockDatastore {
  users: User[]
  applications: Application[]
}

export const generateMockDatastore = (): MockDatastore => {
  const users = generateUsers(25)
  const applications = generateApplicationsForUsers(users)

  return {users, applications}
}
